<template>
  <section id="portfolio-section" class="panel">
    <div
      id="portfolio-section-background"
      ref="portfolioSectionBackground">
      <LazyImage
        v-for="image in sortedArray"
        :key="image.id"
        :responsive-images="image.responsiveImages"
        :image-position-x="image.position.x"
        :image-position-y="image.position.y"
        :background-image-tint="image.backgroundImageTint"
        :filename="image.responsiveImages.src"
        :picture-details="image.pictureDetails"
        :alt="image.pictureDetails" />
    </div>
    <div
      id="portfolio-section-content"
      ref="portfolioSectionContent"
      @mousemove="mouseMove">
      <div id="summary">
        <div>
          <div
            id="logoWrapper"
            :style="{ backgroundColor: 'rgba(255,255,255,0.7)' }">
            <div>
              <img id="logo" :src="companyLogo" alt="Company Logo" />
            </div>
            <div id="companyDate">(<slot name="date" />)</div>
          </div>
        </div>
        <div
          id="content"
          @mouseover="changeBackgroundOpacity(true)"
          @mouseleave="changeBackgroundOpacity(false)">
          <slot name="content" />
          <div
            class="play-pause-button paused"
            id="slideshow-toggle"
            @click="togglePlay"></div>
        </div>
        <div
          ref="photoContext"
          id="currentPhotoInfo"
          :style="{ backgroundColor: companyBrandColour }"></div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap/all'
import LazyImage from '@/components/LazyImage.vue'
import VanillaTilt from 'vanilla-tilt'

export default {
  components: { LazyImage },
  props: {
    companyBrandColour: {
      type: String,
      default: 'black',
    },
    companyLogo: {
      type: String,
    },
    images: {
      type: Object,
      required: true,
      default: () => ({
        responsiveImages: '',
        position: { x: 0, y: 0 },
        backgroundImageTint: '',
        id: 0,
      }),
    },
  },
  data() {
    return {
      // Background image change variables
      secs: 8,
      fade: 0.5,
      backgroundImageElements: [],
      // Tilt/move variables
      xPos: 0,
      yPos: 0,
      rotateX: 5,
      rotateY: 5,
      moveStrength: 10,
      backgroundImageTint: '',
      slideShowActive: true,
      delayedCall: null,
      vanillaTilt: null,
    }
  },
  computed: {
    isTouchDevice() {
      if (
        'ontouchstart' in window ||
        navigator.MaxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      ) {
        return true
      } else {
        return false
      }
    },
    sortedArray: function () {
      function compare(a, b) {
        if (a.id < b.id) return 1
        if (a.id > b.id) return -1
        return 0
      }

      return this.images.slice(0).sort(compare)
    },
  },
  beforeUnmount() {
    // Stop the crossfading images when we leave the page.
    this.slideShowActive = false
    if (this.$refs.portfolioSectionContent.vanillaTilt) {
      this.$refs.portfolioSectionContent.vanillaTilt.destroy()
    }
  },
  mounted() {
    this.backgroundImageElements = gsap.utils.toArray('.lazy-image')
    this.backgroundImageElements.reverse()
    this.backgroundImageElements[0].style.display = 'block'
    let theCurrentlyShownImg =
      this.backgroundImageElements[0].children[0].querySelector('img')
    let theCurrentPhotoContext =
      theCurrentlyShownImg.getAttribute('photoContext')
    this.$refs.photoContext.innerHTML = theCurrentPhotoContext
    this.backgroundSequence(this.backgroundImageElements, this.secs, this.fade)
    gsap.set(
      [
        ' #content ',
        ' #content  > ul > li',
        '#logoWrapper',
        '#currentPhotoInfo',
      ],
      { autoAlpha: 0 }
    )
    var tl = gsap.timeline()
    tl.fromTo(
      ['#content'],
      {
        x: 0,
        y: 0,
        z: 100,
        transformOrigin: 'center center',
      },
      {
        x: 0,
        y: 0,
        z: 0,
        autoAlpha: 1,
        delay: 0.5,
        duration: 0.8,
      },
      '0'
    )
    tl.fromTo(
      ['#logoWrapper'],
      {
        x: -45,
        y: -45,
        z: 100,
        transformOrigin: 'top left',
      },
      {
        x: 0,
        y: 0,
        z: 10,
        autoAlpha: 1,
        duration: 0.3,
      },
      '>'
    )

    tl.fromTo(
      ['#currentPhotoInfo'],
      {
        x: -45,
        y: -45,
        z: 100,
        transformOrigin: 'bottom right',
      },
      {
        x: -5,
        y: -5,
        z: 20, // if this isn't set right then there will be an issue in Safari with rotation and missing text (bug)
        autoAlpha: 1,
        duration: 0.3,
      },
      '>'
    )
    gsap.fromTo(
      ' #content  > ul > *',
      { autoAlpha: 0, y: -10 },
      { autoAlpha: 1, y: 0, z: 20, stagger: 0.1, delay: 1 }
    )
    if (!this.isTouchDevice) {
      this.initialiseVanillaTilt()
    }
  },
  methods: {
    changeBackgroundOpacity(bool) {
      if (bool == true) {
        gsap.to('#content', {
          backgroundColor: 'rgba(34, 34, 34, 0.9)',
          duration: 0.3,
        })
      } else {
        gsap.to('#content', {
          backgroundColor: 'rgba(34, 34, 34, 0.34)',
          duration: 0.3,
        })
      }
    },
    initialiseVanillaTilt() {
      this.vanillaTilt = VanillaTilt.init(this.$refs.portfolioSectionContent, {
        max: 3,
        speed: 200,
        perspective: 800,
        glare: false,
        gyroscope: false, // Boolean to enable/disable device orientation detection,
        'full-page-listening': true,
      })
    },
    hexToRgbA(hex, alpha) {
      var c
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = '0x' + c.join('')
        return (
          'rgba(' +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
          ',' +
          alpha +
          ')'
        )
      }
      throw new Error('Bad Hex')
    },
    startSlideShow(secs, imgs, fade) {
      this.delayedCall = gsap.delayedCall(secs, this.crossfade, [
        this.$refs.photoContext,
        imgs,
        fade,
        this.slideShowActive,
        secs,
      ])
    },
    crossfade(photoContextDiv, imgs, fade, slideShowActive, secs) {
      if (slideShowActive) {
        let theCurrentlyShownImg = imgs[1].children[0].querySelector('img')
        let theCurrentPhotoContext =
          theCurrentlyShownImg.getAttribute('photoContext')
        photoContextDiv.innerHTML = theCurrentPhotoContext
        gsap.set(imgs[1], { display: 'inherit' })
        gsap
          .timeline()
          .to(imgs[0], {
            autoAlpha: 0,
            duration: fade,
            onComplete: () => {
              gsap.set(imgs[0], {
                display: 'none',
              })
            },
          })
          .set(imgs[1], { display: 'inherit' })
          .fromTo(
            imgs[1],
            {
              autoAlpha: 0,
              y: 15,
              scale: 1.3,
              transformOrigin: 'center center',
            },
            {
              autoAlpha: 1,
              duration: fade,
              scale: 1,
              y: 0,
            },
            0
          )

        imgs.push(imgs.shift())
        // start endless run
        if (this.delayedCall != null) {
          this.delayedCall.kill()
        }
        this.delayedCall = gsap.delayedCall(secs, this.crossfade, [
          photoContextDiv,
          imgs,
          fade,
          this.slideShowActive,
          secs,
        ])
      }
    },
    backgroundSequence(imgs, secs, fade) {
      gsap.to(this.$refs.portfolioSectionBackground, {
        visibility: 'shown',
        autoAlpha: 1,
      })
      //only for the first
      gsap.to(imgs[0], {
        autoAlpha: 1,
        duration: 3,
      })
      // start the crossfade
      if (this.slideShowActive) {
        this.startSlideShow(secs, this.backgroundImageElements, fade)
      }
    },
    mouseMove(event) {
      this.xPos = event.clientX / window.innerWidth - 0.5
      this.yPos = event.clientY / window.innerHeight - 0.5

      gsap.to('#portfolio-section-background', {
        rotationY: this.rotateX * this.xPos,
        rotationX: this.rotateY * this.yPos,
        x: this.xPos * this.moveStrength,
        y: this.yPos * this.moveStrength,
        ease: 'power1.out',
      })
    },
    togglePlay() {
      if (this.delayedCall != null) {
        this.delayedCall.kill()
      }
      var element = document.getElementById('slideshow-toggle')
      element.classList.toggle('paused')
      this.slideShowActive = this.slideShowActive ? false : true
      if (this.slideShowActive) {
        this.startSlideShow(this.secs, this.backgroundImageElements, this.fade)
      }
    },
  },
}
</script>

<style lang="scss">
#content > ul > h4 {
  text-transform: uppercase;
}

#content > ul > h4:nth-child(1) {
  margin-top: 0;
}

#portfolio-section {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  transform-style: preserve-3d;
  z-index: 0;
}

#portfolio-section-background {
  margin: auto;
  position: absolute;
  width: 100%;
  height: 100vh;
  background: rgb(0, 0, 0) center center / cover; /* enables rotation effect */
  transform-style: preserve-3d;
  overflow: hidden;
  transform: translateZ(-100px); /* enables rotation effect */
}

#portfolio-section-content {
  color: rgb(255, 255, 255);
  margin: auto;
  height: 100vh;
  width: 100vw;
  position: absolute;
  transform-style: preserve-3d;
  transform: perspective(800px);

  @media #{$mq-tiny-small} {
    width: 80%;
  }

  @media #{$mq-tiny} {
    width: 80%;
  }

  @media #{$mq-small} {
    width: 80%;
  }

  @media #{$mq-medium} {
    width: 90%;
  }
  @media #{$mq-medium} and (orientation: portrait) {
    width: 75%;
  }

  @media #{$mq-large} {
    width: 80%;
  }

  @media #{$mq-xlarge} {
    width: 70%;
  }

  @media #{$mq-xxlarge} {
    width: 50%;
  }
}
#summary {
  position: absolute;
  bottom: 1vmin;
  will-change: transform;
  transform-style: preserve-3d;

  @media #{$mq-tiny} {
    bottom: 23vmin;
  }

  @media #{$mq-tiny-small} {
    bottom: 12vmin;
  }

  @media #{$mq-small} {
    bottom: 10vmin;
  }

  @media #{$mq-medium} {
    bottom: 10vmin;
  }

  @media #{$mq-large} {
    bottom: 4vmin;
  }

  @media #{$mq-xlarge} {
    bottom: 4vmin;
  }

  @media #{$mq-xxlarge} {
    bottom: 7vmin;
  }
}

#content {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(34, 34, 34, 0.31);
  clear: both;
  float: left;
  font: 0.2rem $alt-font2;
  padding: 2vmin;
  position: relative;
  transform-style: preserve-3d;
  will-change: transform;
  max-height: 85vh;
  overflow: auto;
  font-size: 0.5rem;

  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    font-size: 0.1rem;
  }
  @media #{$mq-tiny} {
    font-size: 0.59rem;
  }

  @media #{$mq-tiny-small} {
    font-size: 0.65rem;
  }

  @media #{$mq-tiny-small} and (orientation: landscape) and (hover: none) and (pointer: coarse) {
    font-size: 0.5rem;
  }

  @media #{$mq-small} and (orientation: landscape) and (hover: none) and (pointer: coarse) {
    font-size: 0.558rem;
  }

  @media #{$mq-medium} {
    font-size: 0.8rem;
  }

  @media #{$mq-medium} and (orientation: portrait) {
    font-size: 0.55rem;
  }

  @media #{$mq-large} {
    font-size: 0.5rem;
  }

  @media #{$mq-xlarge} {
    font-size: 0.6rem;
  }

  @media #{$mq-xxlarge} {
    font-size: 0.52rem;
  }

  @media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 428px) {
    font-size: 0.59rem;
  }
}
#currentPhotoInfo {
  background-color: black;
  bottom: 0.3em;
  clear: both;
  color: white;
  float: right;
  font: 2.2vmin $alt-font;
  padding: 0.6em;
  position: relative;
  right: -1.3em;
  text-align: center;
  transform-style: preserve-3d;
  will-change: transform;

  @media #{$mq-tiny} {
    font: 3.9vmin $alt-font;
  }
}

#logoWrapper {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  clear: both;
  float: left;
  left: -10px;
  top: 10px;
  padding: 1em;
  padding: 1.5vmin 1.5vmin;
  position: relative;
  transform-style: preserve-3d;
  width: 15vmin;
  will-change: transform;
  z-index: 1;
  @media #{$mq-tiny} {
    padding: 2.3vmin;
  }
}

#companyDate {
  text-transform: uppercase;
  position: relative;
  float: left;
  clear: both;
  color: rgb(47, 47, 47);
  font: 1.8vmin $alt-font;
  background-color: transparent !important;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.916);
  text-align: center;
  width: 100%;

  @media #{$mq-tiny} {
    font: 2.4vmin $alt-font;
  }
}
#logo {
  object-fit: contain;
  object-position: 0px 0px;
  margin: 0 auto;
  filter: drop-shadow(0rem 0rem 0.3em rgba(0, 0, 0, 0.491));
  padding-bottom: 0.4vmin;
}

#content ul {
  margin: 0;
  padding-top: 0.5em;
}

#content ul.dashed {
  list-style-type: none;
  margin-left: 0;
  padding-left: 1em;
}

#content ul.dashed > li {
  text-indent: -0.6em;
  padding-bottom: 0.2em;
}

#content ul.dashed > li:before {
  content: '-';
}

#contact-form {
  background-color: black;
}

#contact-form {
  background-color: black;
}

#slideshow-toggle {
  position: absolute;
  z-index: 5;
  bottom: 1.5em;
  right: 0.5em;
}

.play-pause-button {
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 1.5em;
  height: 1.5em;

  border-color: transparent transparent transparent #ffffff42;
  transition: 100ms all ease;
  cursor: pointer;

  // play state
  border-style: solid;
  border-width: 0.8em 0 0.8em 1em;

  &.paused {
    border-style: double;
    border-width: 0px 0 0px 1em;
  }

  &:hover {
    border-color: transparent transparent transparent #c9c9c9b0;
  }
}
</style>
